(function () {
    'use strict';

    angular
        .module('salesflare.components.walkthrough', [])
        .factory('sfWalkthrough', sfWalkthrough);

    function sfWalkthrough(
        $rootScope, $state,
        $mdMedia, $mdDialog,
        $timeout, $document, $q,
        authentication, model, team, mockService, utils, statusBarService, sfSetupPanel, flagsService) {

        let activeWalkthrough;
        let walkthroughModel;
        let completed = false;
        const shepherdTour = new Shepherd.Tour({
            defaultStepOptions: {
                popperOptions: {
                    // Adds an offset of 12px to the tooltip so the arrow (instead of the content) starts on the target element
                    modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
                }
            }
        });

        return {
            shepherdTour,
            start,
            hideElement,
            advance,
            hideTooltip,
            showTooltip,
            isShowing,
            isShowingTour,
            isCompleted,
            setCompleted,
            getCurrentStepId,
            getData,
            init,
            acceptDummyContactSuggestion,
            createDummyOpportunity,
            setDummyOpportunityToWon,
            stop,
            stopWalkthrough,
            goToState,
            setSkippedWalkthrough
        };

        function setDummyOpportunityToWon() {

            walkthroughModel.opportunities[0].stage = {
                'id': 'onboarding-stage-won',
                'name': 'Won',
                'order': 5,
                'probability': 100,
                'color': '153,204,0',
                'fixed_stage': 1
            };
        }

        function acceptDummyContactSuggestion(contactName) {

            const suggestions = walkthroughModel.accounts[0].suggestions;
            let acceptedSuggestionIndex;

            for (const [i, suggestion] of suggestions.entries()) {
                if (suggestion.name === contactName) {
                    acceptedSuggestionIndex = i;
                }
            }

            walkthroughModel.accounts[0].customers.push(suggestions[acceptedSuggestionIndex]);
            suggestions.splice(acceptedSuggestionIndex, 1);

            if (getCurrentStepId() === 'contact-found') {
                return Shepherd.activeTour.next();
            }
        }

        function createDummyOpportunity() {

            const dummyOpportunity = {
                'id': 'onboarding-opportunity',
                'owner': {
                    'id': model.me.id,
                    'picture': model.me.picture,
                    'name': model.me.name
                },
                'account': {
                    'id': 'onboarding-account',
                    'name': 'Salesflare',
                    'picture': 'https://lib.salesflare.com/avatars/salesflare_logo.png'
                },
                'stage': {
                    'id': 'onboarding-stage-lead',
                    'name': 'Lead \uD83D\uDE0E',
                    'color': '255,68,68',
                    'fixed_stage': null,
                    'order': 1,
                    'probability': 0
                },
                'lost_reason': null,
                'assignee': {
                    'id': model.me.id,
                    'picture': model.me.picture,
                    'name': model.me.name
                },
                'name': 'Using Salesflare',
                'value': 10000,
                'calculated_value': null,
                'close_date': new Date(),
                'closed': false,
                'pipeline': {
                    'id': 'onboarding-pipeline',
                    'name': 'Default pipeline'
                },
                'isDummy': true
            };

            walkthroughModel.accounts[0].opportunities.push(dummyOpportunity);
            walkthroughModel.opportunities.push(dummyOpportunity);
        }

        function getData(type) {

            return walkthroughModel[type];
        }

        function getCurrentStepId() {

            if (!activeWalkthrough) {
                return;
            }

            return activeWalkthrough.currentStepId;
        }

        function isShowing() {

            if (walkthroughModel || activeWalkthrough) {
                return true;
            }

            return false;
        }

        function isShowingTour() {

            return !!Shepherd.activeTour;
        }

        function setCompleted() {

            completed = true;
        }

        function isCompleted() {

            return completed;
        }

        function hideTooltip() {

            if (!activeWalkthrough || !Shepherd.activeTour?.getCurrentStep()) {
                return;
            }

            Shepherd.activeTour.getCurrentStep().hide();
        }

        function showTooltip() {

            if (!activeWalkthrough) {
                return;
            }

            Shepherd.activeTour.getCurrentStep().show();
        }

        function advance() {

            if (!activeWalkthrough) {
                return;
            }

            const currentStep = Shepherd.activeTour.getCurrentStep();

            const indexOfCurrentStep = Shepherd.activeTour.steps.indexOf(currentStep);

            if (indexOfCurrentStep === Shepherd.activeTour.steps.length - 1) {
                Shepherd.activeTour.next();
                return;
            }

            const attachTo = Shepherd.activeTour.steps[indexOfCurrentStep + 1].options.attachTo;

            // Add extra delay to the 'create-opportunity'-step to ensure the dialog open animation is fully finished before rendering the Shepherd tooltip
            advanceWhenTargetIsInDOM(attachTo.element, currentStep.id === 'create-opportunity' ? 500 : 0);
        }

        function advanceWhenTargetIsInDOM(targetElement, delay) {

            const angularElement = angular.element(targetElement);

            if (!angularElement || (angularElement && !$document[0].body.contains(angularElement[0]))) {
                $timeout(function () {

                    advanceWhenTargetIsInDOM(targetElement, delay);
                }, 100);

                return;
            }

            $timeout(Shepherd.activeTour.next, delay);
        }

        function hideElement(name) {

            return (activeWalkthrough && activeWalkthrough.hideElements[name]);
        }

        function start() {

            if (!$mdMedia('gt-md')) {
                return;
            }

            $timeout(function () {

                shepherdTour.start();
            }, 500);
        }

        // Returns a promise so we can wait for it before trying to start the walkthrough
        function init() {

            let options;
            const currentDate = new Date();
            const year = moment(currentDate).subtract(1, 'months').format('YYYY');
            const month = moment(currentDate).subtract(1, 'months').format('MM');
            const date = {
                year,
                month
            };


            if (!$mdMedia('gt-md')) {
                return $q.resolve();
            }

            if (model.isLoggedIn) {

                options = {
                    'user': model.me,
                    date
                };
                return mockService.start('walkthrough', options).then(function () {

                    walkthroughModel = mockService.model;
                    initWalkthrough();
                    return $q.resolve();
                });
            }
            else {
                options = {
                    'user': {
                        id: 1,
                        picture: 'images/person.png',
                        is_admin: true,
                        name: 'Demo',
                        firstname: 'Demo',
                        'team': {
                            'subscribed': false,
                            'plan': 3
                        },
                        'plan_flags': {
                            'multiStepWorkflows': 1,
                            'permissions': 1,
                            'customDashboards': 0
                        },
                        'flags': [{
                            'id': 2,
                            'name': 'workflowBeta',
                            'state': true
                        }],
                        'role': {
                            'permissions': {
                                'ced': 'NONE'
                            }
                        }
                    },
                    date
                };

                return mockService.start('walkthrough-offline', options).then(function () {

                    walkthroughModel = mockService.model;
                    initWalkthrough();
                    return $q.resolve();
                });
            }
        }

        function initWalkthrough() {

            if (!activeWalkthrough) {
                activeWalkthrough = {
                    hideElements: {
                        'fab': true,
                        'accountInfo.relationship': true,
                        'accountInfo.opportunities': true,
                        'accountInfo.suggestedContacts': true,
                        'accountInfo.contacts': true,
                        'accountInfo.peopleInMyNetwork': true,
                        'accountInfo.team': true,
                        'mainMenu.accounts': true,
                        'mainMenu.opportunities': true,
                        'mainMenu.contacts': true,
                        'mainMenu.insights': true,
                        'mainMenu.dashboards': true,
                        'mainMenu.support': true,
                        'mainMenu.settings': true,
                        'mainMenu.tasks': true,
                        'mainMenu.workflows': true,
                        'mainMenu.notifications': true,
                        feed: true
                    }
                };

                // Check if steps are already added
                if (!shepherdTour.getById('first-account')) {
                    const steps = [
                        {
                            id: 'first-account',
                            title: 'Here is your first account &#x1F449',
                            text: 'An account is a company you are selling to. Salesflare automatically collects information on accounts for you.',
                            attachTo: {
                                element: '#account-information-section',
                                on: 'left'
                            },
                            buttons: [
                                {
                                    text: 'NEXT TIP',
                                    action: shepherdTour.next
                                }
                            ]
                        },
                        {
                            id: 'contact-found',
                            title: 'Salesflare suggests contacts automatically',
                            text: 'Salesflare detects in your emails who you know at your accounts. &#x1F60E \n \n Click the checkmark to accept the suggested contact. &#x1F449',
                            attachTo: {
                                element: '.contact-suggestion',
                                on: 'left'
                            },
                            scrollToElement: '#account-info-suggested-contacts'
                        },
                        {
                            id: 'interactions-found',
                            title: 'Salesflare keeps communication timelines for you',
                            text: 'When you add contacts to an account, Salesflare pulls in all the email interactions. &#x1F4E7 \n \n If you connect your calendar and phone, meetings and calls start showing as well. &#x1F4C5 &#x1F4DE',
                            attachTo: {
                                element: '#feed',
                                on: 'left'
                            },
                            buttons: [
                                {
                                    text: 'NEXT TIP',
                                    action: shepherdTour.next
                                }
                            ]
                        },
                        {
                            id: 'create-opportunity',
                            title: 'Let\'s create your first opportunity!',
                            text: 'Sales opportunities indicate that there is an opportunity to sell something to the account. &#x1F4B0',
                            attachTo: {
                                element: '#create-opportunity-button',
                                on: 'left'
                            },
                            scrollToElement: '#account-info-opportunities'
                        },
                        {
                            id: 'actual-create-opportunity',
                            title: 'Just hit create',
                            text: 'No need to fill in any fields for now. &#x1F44C',
                            classes: 'small',
                            attachTo: {
                                element: '#save-opportunity',
                                on: 'top'
                            }
                        },
                        {
                            id: 'go-to-opportunities',
                            title: 'All opportunities can be found here',
                            text: 'Let\'s go check it out! &#x1F64C',
                            attachTo: {
                                element: '#main-menu-item-opportunities',
                                on: 'right'
                            }
                        },
                        {
                            id: 'drag-opportunity',
                            title: 'Drag this opportunity to won',
                            text: 'You can organize your pipeline by dragging and dropping. Make some magic happen! &#x1F386',
                            attachTo: {
                                element: '.opportunity-draggable-item',
                                on: 'right'
                            },
                            buttons: [
                                {
                                    text: 'DO IT FOR ME',
                                    action: advance
                                }
                            ]
                        }
                    ];

                    shepherdTour.addSteps(steps);
                }

                shepherdTour.on('show', function (event) {

                    activeWalkthrough.currentStepId = event.step.id;

                    switch (event.step.id) {
                        case 'first-account':
                            activeWalkthrough.hideElements['mainMenu.accounts'] = false;
                            break;
                        case 'contact-found':
                            activeWalkthrough.hideElements['accountInfo.suggestedContacts'] = false;
                            break;
                        case 'create-opportunity':
                            activeWalkthrough.hideElements['accountInfo.opportunities'] = false;
                            break;
                        case 'go-to-opportunities':
                            activeWalkthrough.hideElements['mainMenu.opportunities'] = false;
                            break;
                        case 'interactions-found':
                            activeWalkthrough.hideElements['accountInfo.contacts'] = false;
                            activeWalkthrough.hideElements.feed = false;
                            break;
                        case 'drag-opportunity':
                            activeWalkthrough.hideElements['mainMenu.accounts'] = true;
                            break;
                    }

                    $timeout(function () {

                        if (event.step.options.scrollToElement) {
                            const scrollToElement = angular.element(event.step.options.scrollToElement)[0];

                            if (scrollToElement && !utils.isElementInViewport(scrollToElement)) {
                                utils.scrollIntoView(scrollToElement);
                            }
                        }

                        $rootScope.$apply();
                    });
                });
            }
        }

        function stopWalkthrough(toState, skipReason) {

            stop();

            if (!model.isLoggedIn) {
                return $mdDialog.show({
                    fullscreen: true,
                    escapeToClose: false,
                    controller: 'SignUpController',
                    templateUrl: 'partials/signup.html',
                    locals: {
                        data: {
                            notLoggedIn: true,
                            skippedReason: skipReason,
                            signUpAfterWalkthrough: true
                        }
                    },
                    bindToController: true
                }).finally(function () {

                    return goToState(toState);
                });
            }

            if (skipReason) {
                setSkippedWalkthrough(skipReason);
            }
            else {
                setSkippedWalkthrough('no');
            }

            return goToState(toState).then(function () {

                // Show status bar again
                statusBarService.setCanBeVisible(true);
            });
        }

        function goToState(toState, dontShowSetupPanel) {

            return authentication.fetchMe().then(function () {

                return $state.go(toState, {}, { reload: true }).then(function () {

                    if (!dontShowSetupPanel && $mdMedia('gt-sm') && flagsService.get('gamifiedTrial') === true) {
                        $timeout(sfSetupPanel.show, 700);
                    }
                });
            });
        }

        function setSkippedWalkthrough(skippedWalkthroughValue) {

            // Only update when admin and first user
            if (model.me) {
                if (model.me.is_admin && model.me.team.total_user_count === 1) {
                    return team.update({ skipped_walkthrough: skippedWalkthroughValue });
                }
            }
            else {
                // We need the model to make sure that the user is an admin and the first user
                return authentication.fetchMe()
                    .then(() => {

                        if (model.me.is_admin && model.me.team.total_user_count === 1) {
                            team.update({ skipped_walkthrough: skippedWalkthroughValue });
                        }
                    })
                    .catch(angular.noop);
            }
        }

        function stop() {

            if (mockService.isRunning) {
                mockService.stop();
            }

            // Unbind .on('complete') event
            // Not doing this caused problems when restarting the walkthrough via the browser's back button
            if (shepherdTour) {
                shepherdTour.off('complete');
            }

            if (Shepherd.activeTour) {
                Shepherd.activeTour.cancel();
            }

            $rootScope.fireworks = false;
            $rootScope.showBackdrop = false;
            activeWalkthrough = null;
            walkthroughModel = null;
        }
    }
})();
