import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { MediaService } from '@core/services/media.service';
@Component({
    selector: 'sfx-customize-fields',
    templateUrl: './customize-fields.component.html',
    styleUrls: ['./customize-fields.component.scss']
})
export class CustomizeFieldsComponent implements OnInit {

    constructor(
        public media: MediaService,
        public stateService: StateService
    ) {}

    public ngOnInit(): void {}

    public close = (): any =>  {

        this.stateService.go('settings');
    };

    public createCustomField(): void {

        const itemClass = this.stateService.$current.name.split('.').pop();

        this.stateService.go('settings.createCustomField', { itemClass: itemClass || 'account' });
    }
}
