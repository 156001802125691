import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'sfx-enter-recovery-code-dialog',
    templateUrl: './enter-recovery-code-dialog.component.html',
    styleUrls: ['./enter-recovery-code-dialog.component.scss']
})
export class SfxEnterRecoveryCodeDialog {
    @ViewChild('recoveryCodeForm') recoveryCodeForm!: FormGroup;
    recoveryCode = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { email: string, password: string },
        @Inject('windowService') public windowService: any,
        @Inject('exceptionHandler') public exceptionHandler: any,
        @Inject('authenticationService') private authenticationService: any,
        public dialogRef: MatDialogRef<SfxEnterRecoveryCodeDialog>
    ) {
        dialogRef.updateSize('360px');
    }

    public verify = () => {

        if (this.recoveryCodeForm?.invalid) {
            return;
        }

        return this.windowService.grecaptcha.ready(() => {

            return this.windowService.grecaptcha.execute('6LeIk8EUAAAAAIKkocBie39wYk-ril7DzO5AbUvK', { action: 'login' }).then((reCaptchaToken: any) => {

                return this.authenticationService.logIn(this.data.email, this.data.password, reCaptchaToken, null, this.recoveryCode).then((response: any) => {

                    return this.dialogRef.close({ token: response.data.token });
                }).catch(() => {

                    this.recoveryCodeForm?.controls.recoveryCode.setErrors({ 'invalidRecoveryCode': true });
                });
            }, (err: any) => {

                if (err) {
                    this.exceptionHandler(err);
                }
            });
        }, (error: any) => {

            if (error) {
                this.exceptionHandler(error);
            }
        });
    };

    public close = () => {

        this.dialogRef.close();
    };
}
