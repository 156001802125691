import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { environment } from '@environment/environment';
import { StateService } from '@uirouter/core';
import { CdkVirtualScrollableElement } from '@angular/cdk/scrolling';
import { filter, map, pairwise, throttleTime } from 'rxjs';
import { MediaService } from '@core/services/media.service';
import { MatDialog } from '@angular/material/dialog';
import { SfxDialog } from '@shared/components/dialog/dialog.component';

@Component({
    selector: 'sfx-refer-a-friend',
    templateUrl: './refer-a-friend.component.html',
    styleUrls: ['./refer-a-friend.component.scss'],
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class ReferAFriendComponent implements OnInit, AfterViewInit {

    shareQuote = 'I\'ve been using Salesflare CRM to follow up our leads and I\'m absolutely loving it! I highly recommend you to check it out!';
    shareLink = '';
    referralCode: string = this.modelService.me.referral_code;
    referralReward: string = this.modelService.me.referral_reward;
    referralCount = 0;

    facebookShareLink = 'https://www.facebook.com/sharer/sharer.php';
    XShareLink = 'https://x.com/intent/tweet';
    whatsappShareLink = 'https://api.whatsapp.com/send';

    inviteText = '';

    alreadySignedUpFriend: any = { email: null };
    personalInvite: any = { email: null };

    currencyHtml = this.modelService.me.team.billing_currency.html;

    @ViewChild(CdkVirtualScrollableElement)
    scrollable!: CdkVirtualScrollableElement;

    rowSize = this.media.isActive('gt-sm') ? 48 : 71;

    batchSize = 20;
    offset = 0;
    endReached = false;
    oneMonthAgo: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));

    public referees:any[] = [];
    public loading = true;
    public doneLoading = false;
    public showEmptyState = false;
    public statusStrings: any = {
        'started_trial': 'Started trial',
        'subscribed': 'Subscribed',
        'trial_expired': 'Trial expired'
    };
    public emptyStateImage = 'images/customers_empty.png';
    public emptyStateText = 'When you refer friends, they will show up here.';

    constructor(
        @Inject('modelService') public modelService: any,
        @Inject('personService') public personService: any,
        @Inject('referralsService') public referralsService: { getReferees(requestConfig: any, options?: any): Promise<any> , create(email: string): Promise<any>, delete(id: number): Promise<any> },
        @Inject('utilsService') public utilsService: any,
        public media: MediaService,
        public $state: StateService,
        public matDialog: MatDialog
    ) {}

    ngOnInit(): void {

        this.shareLink = `${location.origin}/#/signup/?refer=${encodeURIComponent(this.referralCode)}`;

        if (environment.referUrl) {
            this.shareLink = `${environment.referUrl}?refer=${encodeURIComponent(this.referralCode)}`;
        }

        this.facebookShareLink += `?u=${encodeURIComponent(this.shareLink)}&quote=${encodeURIComponent(this.shareQuote)}`;
        this.XShareLink += `?url=${encodeURIComponent(this.shareLink)}&text=${encodeURIComponent(this.shareQuote)}`;
        this.whatsappShareLink += `?text=${encodeURIComponent(this.shareQuote)}%20${encodeURIComponent(this.shareLink)}`;

        this.inviteText = `
            <div>Salesflare is the perfect CRM for any B2B business that wants to make more sales with less work. Individuals and teams all use it to synchronize emails, calendars, phones, social media &amp; have it all organize for them.&nbsp;</div>
            <div><br></div>
            <div>Sign up for Salesflare today and both you and I will get a complimentary ${this.modelService.me.team.billing_currency.html}${this.modelService.me.referral_reward} Amazon gift card!</div>
            <div><br></div>
            <div><br></div>
            <div>
                <a target="_blank" style="align-items: center; appearance: button; background: none 0% 0% / auto scroll padding-box border-box rgb(0, 130, 213); border-color: rgb(255, 255, 255); border-radius: 2px; border-style: none; border-width: 0px; border-image: none 100% / 1 / 0 stretch; box-shadow: rgba(0, 0, 0, 0.26) 0px 2px 5px 0px; box-sizing: border-box; display: inline-block; font: 500 14px / 36px 'Source Sans 3', sans-serif; height: 36px; letter-spacing: 0.14px; margin: 0px; min-height: 0px; min-width: 88px; outline: rgb(255, 255, 255) none 0px; overflow: hidden; padding: 0px 6px; position: relative; text-align: center; text-decoration: none solid rgb(255, 255, 255); text-indent: 0px; text-rendering: auto; text-shadow: none; text-size-adjust: 100%; text-transform: uppercase; transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 0s, background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 0s; vertical-align: middle; white-space: nowrap; width: 148.203px; word-spacing: 0px; writing-mode: horizontal-tb; -webkit-box-align: center; -webkit-box-direction: normal; -webkit-font-smoothing: antialiased; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-border-image: none; orphans: 2; widows: 2; -webkit-text-stroke-width: 0px;" class="button" href="${this.shareLink}">
                    <span style="color: rgb(255, 255, 255); cursor: pointer; display: inline; font: 500 14px / 36px 'Source Sans 3', sans-serif; height: auto; letter-spacing: 0.14px; padding: 0px 8px; text-align: center; text-decoration-thickness: auto; text-indent: 0px; text-rendering: auto; text-shadow: none; text-size-adjust: 100%; text-transform: uppercase; white-space: nowrap; width: auto; word-spacing: 0px; writing-mode: horizontal-tb; -webkit-box-direction: normal; -webkit-font-smoothing: antialiased; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" class="button-text">
                        GET STARTED
                    </span>
                </a>
            </div>
        `;

        // Fetch count
        this.referralsService.getReferees({}, { returnCountOnly: true }).then((response) => {

            this.referralCount = Number.parseInt(response.headers('x-result-count'));
        });

        this.loadBatch();
    }

    ngAfterViewInit() {

        this.scrollable.elementScrolled().pipe(
            map(() => this.scrollable.measureScrollOffset('bottom')),
            pairwise(),
            filter(([previousBottomOffset, currentBottomOffset]) => (!this.endReached && currentBottomOffset < previousBottomOffset && currentBottomOffset < this.rowSize * 2)),
            throttleTime(500)
        ).subscribe(() => {
            this.loadBatch();
        });
    }

    public loadBatch = () => {

        this.referralsService.getReferees({ limit: this.batchSize, offset: this.referees.length }).then((response) => {

            const batch = response.data;

            this.referees = [...this.referees, ...batch];

            if (batch.length < this.batchSize) {
                this.endReached = true;
                if (batch.length === 0) {
                    this.showEmptyState = true;
                }
            }

            if (!this.doneLoading) {
                this.loading = false;
                this.doneLoading = true;
            }
        });
    }

    public createReferral = () => {

        return this.referralsService.create(this.alreadySignedUpFriend.email).then(() => {

            this.utilsService.showSuccessToast('Congrats! Your friend has been linked to you.');
            this.referees = [];
            this.endReached = false;
            this.loadBatch();
        }).finally(() => {
            this.alreadySignedUpFriend.email = null;
        });
    }

    public previewPersonalInviteEmail = () => {

        return this.personService.get(this.personalInvite.email).then((response: any) => {

            const filteredPersons = response.data.filter((person: any) => {

                if (!person.email) {
                    return false;
                }

                return this.personalInvite.email.toLowerCase() === person.email.toLowerCase();
            });

            const to = [];
            if (filteredPersons?.length > 0) {
                const person = filteredPersons[0];
                to.push({
                    name: person.name,
                    email: person.email,
                    picture: person.picture
                });
            }
            else {
                to.push({
                    name: this.personalInvite.email,
                    email: this.personalInvite.email,
                    picture: null
                });
            }

            const emailComposeObject = {
                to,
                subject: `${this.modelService.me.name} has invited you to try Salesflare`,
                body: this.inviteText
            };

            this.$state.go('composeEmail', emailComposeObject);
        });
    }

    public deleteReferral(referralId: number) {

        const confirmationDialog = this.matDialog.open(SfxDialog, {
            panelClass: 'sfx-dialog-container-unthemed',
            data: {
                title: 'Are you sure you want to delete this referral?',
                confirm: 'I\'m sure',
                close: 'Cancel'
            }
        });

        confirmationDialog.afterClosed().subscribe((state) => {

            if (state.confirmed) {
                this.referralsService.delete(referralId).then(() => {

                    this.referees = this.referees.filter((ref) => ref.id !== referralId);
                });
            }
        });

    }

    public moreThanOneMonthAgo = (date: number) => {

        if (!date) {
            return false;
        }

        return new Date(date) < this.oneMonthAgo;
    };
}
