(function () {
    'use strict';

    angular
        .module('salesflare.components.referralprogram', [
            'salesflare.components.referralprogram.referees'
        ])
        .component('sfReferralProgram', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/referralprogram/referralprogram.html'
        });

    function controller($mdMedia, $state, model, $location, persons, utils, referralsService, config) {

        const vm = this;

        const SHARE_QUOTE = 'I\'ve been using Salesflare CRM to follow up our leads and I\'m absolutely loving it! I highly recommend you to check it out!';

        vm.$mdMedia = $mdMedia;
        vm.me = model.me;

        vm.shareLink = `${$location.absUrl().split($location.path())[0]}/signup/?refer=${encodeURIComponent(vm.me.referral_code)}`;
        if (config.referUrl) {
            vm.shareLink = `${config.referUrl}?refer=${encodeURIComponent(vm.me.referral_code)}`;
        }

        vm.facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(vm.shareLink)}&quote=${encodeURIComponent(SHARE_QUOTE)}`;
        vm.XShareLink = `https://x.com/intent/tweet?url=${encodeURIComponent(vm.shareLink)}&text=${encodeURIComponent(SHARE_QUOTE)}`;
        vm.whatsappShareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(SHARE_QUOTE)}%20${encodeURIComponent(vm.shareLink)}`;

        vm.emptyStateText = 'When you refer friends, they will show up here.';

        const inviteText = `
            <div>Salesflare is the perfect CRM for any B2B business that wants to make more sales with less work. Individuals and teams all use it to synchronize emails, calendars, phones, social media &amp; have it all organize for them.&nbsp;</div>
            <div><br></div>
            <div>Sign up for Salesflare today and both you and I will get a complimentary ${vm.me.team.billing_currency.html}${vm.me.referral_reward} Amazon gift card!</div>
            <div><br></div>
            <div><br></div>
            <div>
                <a target="_blank" style="align-items: center; appearance: button; background: none 0% 0% / auto scroll padding-box border-box rgb(0, 130, 213); border-color: rgb(255, 255, 255); border-radius: 2px; border-style: none; border-width: 0px; border-image: none 100% / 1 / 0 stretch; box-shadow: rgba(0, 0, 0, 0.26) 0px 2px 5px 0px; box-sizing: border-box; display: inline-block; font: 500 14px / 36px 'Source Sans 3', sans-serif; height: 36px; letter-spacing: 0.14px; margin: 0px; min-height: 0px; min-width: 88px; outline: rgb(255, 255, 255) none 0px; overflow: hidden; padding: 0px 6px; position: relative; text-align: center; text-decoration: none solid rgb(255, 255, 255); text-indent: 0px; text-rendering: auto; text-shadow: none; text-size-adjust: 100%; text-transform: uppercase; transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 0s, background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 0s; vertical-align: middle; white-space: nowrap; width: 148.203px; word-spacing: 0px; writing-mode: horizontal-tb; -webkit-box-align: center; -webkit-box-direction: normal; -webkit-font-smoothing: antialiased; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-border-image: none; orphans: 2; widows: 2; -webkit-text-stroke-width: 0px;" class="button" href="${vm.shareLink}">
                    <span style="color: rgb(255, 255, 255); cursor: pointer; display: inline; font: 500 14px / 36px 'Source Sans 3', sans-serif; height: auto; letter-spacing: 0.14px; padding: 0px 8px; text-align: center; text-decoration-thickness: auto; text-indent: 0px; text-rendering: auto; text-shadow: none; text-size-adjust: 100%; text-transform: uppercase; white-space: nowrap; width: auto; word-spacing: 0px; writing-mode: horizontal-tb; -webkit-box-direction: normal; -webkit-font-smoothing: antialiased; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" class="button-text">
                        GET STARTED
                    </span>
                </a>
            </div>
        `;

        vm.referees = {
            toLoad: 0,
            numLoaded: 0,
            items: [],
            topIndex: 0,

            getItemAtIndex: function (index) {

                if (index > this.numLoaded) {
                    this.fetchMoreItems(index);

                    return null;
                }

                return this.items[index];
            },

            getLength: function () {

                if (this.items.length < this.numLoaded) {
                    return this.items.length;
                }

                return this.numLoaded + 20;
            },

            fetchMoreItems: function (index) {

                if (this.toLoad < index) {
                    this.toLoad += 20;

                    const bound = angular.bind(this, refereeResponseHandler);

                    const requestConfig = {};

                    if (this.items.length === 0) {
                        referralsService.getReferees(requestConfig, { returnCountOnly: true }).then(function (response) {

                            vm.referralCount = Number.parseInt(response.headers('x-result-count'));
                        });
                        requestConfig.limit = this.toLoad;
                        return referralsService.getReferees(requestConfig).then(bound, null, bound);
                    }

                    requestConfig.limit = 20;
                    requestConfig.offset = this.numLoaded;
                    return referralsService.getReferees(requestConfig).then(angular.bind(this, function (response) {

                        this.items = [...this.items, ...response.data];
                        this.numLoaded = this.toLoad;
                    }));
                }
            },

            // Forces reset of object
            reload: function () {

                this.toLoad = 0;
                this.numLoaded = 0;
                this.items = [];
                this.doneLoading = false;
                this.topIndex = 0;
            }
        };

        function refereeResponseHandler(response) {

            this.items = response.data;
            this.numLoaded = this.toLoad;
            vm.showEmptyState = response.data.length === 0;
            this.doneLoading = true;
        }

        vm.onReferralListChange = () => {

            vm.referees.reload();
        };

        vm.createReferral = () => {

            return referralsService.create(vm.alreadySignedUpFriendEmail).then(() => {

                utils.showSuccessToast('Congrats! Your friend has been linked to you.');
                vm.referees.reload();
            }).finally(() => {
                vm.alreadySignedUpFriendEmail = null;
            });
        };

        vm.previewPersonalInviteEmail = () => {

            return persons.get(vm.personalInviteEmail).then(function (response) {

                const filteredPersons = response.data.filter(function (p) {

                    if (!p.email) {
                        return false;
                    }

                    return vm.personalInviteEmail.toLowerCase() === p.email.toLowerCase();
                });

                const to = [];
                if (filteredPersons?.length > 0) {
                    const person = filteredPersons[0];
                    to.push({
                        name: person.name,
                        email: person.email,
                        picture: person.picture
                    });
                }
                else {
                    to.push({
                        name: vm.personalInviteEmail,
                        email: vm.personalInviteEmail,
                        picture: null
                    });
                }


                const emailComposeObject = {
                    to,
                    subject: `${vm.me.name} has invited you to try Salesflare`,
                    body: inviteText
                };

                return $state.go('composeEmail', emailComposeObject);
            });
        };

        vm.back = () => {
            $state.go('settings');
        };
    }
})();
