(function () {
    'use strict';

    angular
        .module('salesflare')
        .config(function ($mdDialogProvider) {

            $mdDialogProvider.addPreset('viewCreditUsageDialog', {
                // @ngInject
                options: function ($mdMedia) {
                    return {
                        fullscreen: !$mdMedia('gt-sm'),
                        templateUrl: 'app-ajs/components/viewCreditUsageDialog/viewCreditUsageDialogTemplate.html',
                        controllerAs: 'vm',
                        controller: viewCreditUsageDialogController,
                        bindToController: true,
                        clickOutsideToClose: true,
                        escapeToClose: true,
                        multiple: true
                    };
                }
            });

            /*
             * Annotate the function so uglyfying (more specifically mangling) the file doesn't break it.
             * @ngInject
             */
            function viewCreditUsageDialogController($mdDialog, $mdMedia) {

                const vm = this;

                vm.$mdMedia = $mdMedia;

                vm.$onInit = () => {

                    vm.currentMonthHeader = `This month's usage (${moment(vm.historical_team_credit_usage[vm.historical_team_credit_usage.length - 1].start_date).format('MMM D')} - ${moment(vm.historical_team_credit_usage[vm.historical_team_credit_usage.length - 1].end_date).format('MMM D')})`;

                    const monthLabels = ['11m ago', '10m ago', '9m ago', '8m ago', '7m ago', '6m ago', '5m ago', '4m ago', '3m ago', '2m ago', 'Last month', 'This month'];
                    const chartJsColorObject = {
                        backgroundColor: '#303c4f',
                        pointBackgroundColor: '#303c4f',
                        pointHoverBackgroundColor: '#303c4f',
                        borderColor: '#303c4f',
                        pointBorderColor: '#fff',
                        pointHoverBorderColor: '#303c4f'
                    };

                    vm.chartData = vm.historical_team_credit_usage.map((dataPoint) => {

                        return dataPoint.usage;
                    });
                    vm.chartLabels = vm.historical_team_credit_usage.map((dataPoint, index) => {

                        return monthLabels[monthLabels.length - vm.historical_team_credit_usage.length + index];
                    });
                    vm.chartDatasetOverride = {
                        lineTension: 0,
                        fill: false,
                        ...chartJsColorObject
                    };
                    vm.chartOptions = {
                        maintainAspectRatio: false, // Needed to fill up our chart container fully.
                        tooltips: {
                            titleFontFamily: '\'Source Sans 3\', \'sans-serif\'',
                            bodyFontFamily: '\'Source Sans 3\', \'sans-serif\'',
                            enabled: true,
                            mode: 'single'
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    fontFamily: '\'Source Sans 3\', \'sans-serif\'',
                                    beginAtZero: true
                                }
                            }],
                            xAxes: [{
                                ticks: {
                                    fontFamily: '\'Source Sans 3\', \'sans-serif\''
                                },
                                gridLines: {
                                    display: false
                                }
                            }]
                        }
                    };
                    // Add the colors for each data point
                    vm.chartColors = vm.historical_team_credit_usage.map(() => {

                        return chartJsColorObject;
                    });
                };

                vm.cancel = $mdDialog.cancel;
            }
        });
})();
