(function () {
    'use strict';

    angular
        .module('salesflare.components.messageBox', [])
        .component('sfMessageBox', {
            templateUrl: 'app-ajs/components/messageBox/messageBox.html',
            controller,
            controllerAs: 'vm',
            bindings: {
                onDescriptionChange: '&',
                onKeyboardSave: '&',
                description: '<',
                users: '<',
                picture: '<',
                currentUserId: '<'
            }
        });

    function controller($element, $sanitize, $window) {

        const vm = this;
        let editableDiv;

        vm.$onInit = function () {

            editableDiv = $element.find('div');

            editableDiv.html($sanitize(vm.description));

            editableDiv.textcomplete([{
                match: /\B@(\w*)$/,
                search: function (term, callback) {

                    const items = [];
                    if (!term || term.toLowerCase()[0] === 't') {
                        items.push({ picture: vm.picture, name: 'Team' });
                    }

                    vm.users.forEach(function (user) {

                        if (user.name.toLowerCase().indexOf(term.toLowerCase()) === 0 && user.id !== vm.currentUserId) {
                            items.push(user);
                        }
                    });

                    return callback(items);
                },
                index: 1,
                cache: true,
                template: function (item) {

                    // Mimic sf-avatar, somehow using sf-avatar it does not compile the custom element, might have something to do with the src usually being on the scope
                    return '<md-list-item>'
                        + '    <sf-avatar class="sf-avatar_small">'
                        + '        <div class="sf-avatar_face" style="background-image: url(' + item.picture + ')"></div>'
                        + '    </sf-avatar>'
                        + '  <span class="accent">' + item.name + '</span>'
                        + '</md-list-item>';
                },
                replace: function (item) {

                    if (item.type === 'user') {
                        return '<span contenteditable="false" class="mention" id="' + item.id + '"><a href="#/users/' + item.id + '/view">' + item.name + '</a></span>&nbsp';
                    }

                    return '<span contenteditable="false" class="mention">' + item.name + '</span>&nbsp';
                }
            }], {
                placement: 'top',
                rightEdgeOffset: 16
            })
                .on({
                    'textComplete:select': function () {

                        return onInternalDescriptionChange();
                    }
                });

            // Make sure to do an internal description change, to apply the description field to the body
            onInternalDescriptionChange();
            return moveCursorToEnd(editableDiv);
        };

        vm.$onChanges = function (changes) {

            if (changes.description && !changes.description.isFirstChange() && vm.description !== editableDiv.html()) {
                editableDiv.html($sanitize(vm.description));
            }
        };

        vm.$onDestroy = function () {

            editableDiv.textcomplete('destroy');
        };

        // Make Ctrl/Cmd + enter the shortcut for sending a note
        vm.onKeyDown = function ($event) {

            // Navigator.platform can hold an array of values regarding the platform used by the user (beware that this value is changeable by the browser so not 100% reliable)
            // All possible values regarding Mac computers up to now are starting with 'Mac', that's why we check for it here to determine what shortcut is available for the user
            const sendKey = (navigator.platform.indexOf('Mac') === 0 ? $event.metaKey : $event.ctrlKey) || false;
            if (($event.which === 10 || $event.which === 13) && sendKey) {
                $event.preventDefault();

                if (vm.onKeyboardSave) {
                    return vm.onKeyboardSave();
                }
            }
        };

        // Fix for https://github.com/angular/angular.js/issues/528
        vm.onKeyUp = function ($event) {

            // <br> happens when doing only shft+enter and then removing the newlines, the last one remains until you start typing again
            if ($event.currentTarget.innerHTML === '<br>') {
                $event.currentTarget.innerHTML = '';
            }

            return onInternalDescriptionChange();
        };

        function onInternalDescriptionChange() {

            if (vm.onDescriptionChange) {
                const isTeamMentioned = editableDiv.find('.mention:not([id])').length > 0;
                let mentions = [];

                if (isTeamMentioned) {
                    mentions = vm.users.map(function (user) {

                        return user.id;
                    });
                }
                else {
                    mentions = editableDiv.find('.mention[id]').toArray().map(function (mention) {

                        return mention.id;
                    });
                }

                return vm.onDescriptionChange({
                    $event: {
                        description: editableDiv.html(), // When clicking to select mention instead of using ENTER, the binding to `message.body` does not get updated for a reason so we do it for Angular
                        mentions
                    }
                });
            }
        }

        function moveCursorToEnd(el) {

            el.focus();

            if (angular.isDefined($window.getSelection) && angular.isDefined($window.document.createRange)) {
                const range = $window.document.createRange();
                range.selectNodeContents(el[0]);
                range.collapse(false);
                const sel = $window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            }
            else if (angular.isDefined($window.document.body.createTextRange)) {
                const textRange = $window.document.body.createTextRange();
                textRange.moveToElementText(el[0]);
                textRange.collapse(false);
                textRange.select();
            }
        }
    }
})();
