<sfx-toolbar enableDivider>
    <div data-layout-align="space-between center">
        <span>
            API keys
        </span>
        <a mat-button [color]="media.isActive('gt-sm')?'primary':''" track-id="api-docs" [href]="apiDocsUrl" target="_blank" rel="noopener">API DOCS</a>
    </div>
</sfx-toolbar>

<sfx-content>
    <ng-container *ngIf="initialLoad && (apiKeys.length < 1)">
        <sfx-empty-state image="images/api_keys_empty.png">
            <h1 class="accent">No API keys created yet!</h1>
            <h1 class="accent">Click the button below to create an API key.</h1>
        </sfx-empty-state>
    </ng-container>
    <sfx-section *ngIf="initialLoad && (apiKeys.length > 0)" disableContentPadding >

        <mat-grid-list [cols]="media.isActive('xs') ? 1 : media.isActive('md') ? 1 : 2" gutterSize="16px" rowHeight="182px">
            <mat-grid-tile *ngFor="let apiKey of apiKeys">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title><h3>{{apiKey.name}}</h3></mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <p class="ellipsis">{{apiKey.api_key}}</p>
                        <p *ngIf="apiKey.last_used" [title]="apiKey.last_used">Last used: {{apiKey.last_used | date: 'medium'}}</p>
                        <div *ngIf="!apiKey.last_used" class="last-used-placeholder"></div>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button [cdkCopyToClipboard]="apiKey.api_key" track-id="copy-apikey">Copy</button>
                        <button mat-button (click)="editApiKey(apiKey)" track-id="update-apikey">Edit</button>
                        <button mat-button (click)="deleteApiKey(apiKey)" track-id="delete-apikey">Delete</button>
                    </mat-card-actions>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </sfx-section>
</sfx-content>

<sfx-speed-dial class="fab-dial" aria-label="connect-email" (click)="createApiKey()">
</sfx-speed-dial>